export default {
  name: "warrantdetails",
  created() {
    window.toUnderlyingChart = this.toUnderlyingChart;
    window.printTable = this.printTable;
    window.getChartData = this.getChartData;
  },
  mounted() {
    this.$nextTick(function () {
      this.spanAnimate();
      this.dateClick();
      this.getRicList();
      this.$js.jqcombobox("#details-combobox");
    });
  },
  data() {
    return {
      chartDate: "m",
      chartRic: "",
      underlyingRic: "",
      dsply_nmll: "",
      underlying_curr: "",
      currency: "",
      mouse: false
    };
  },
  methods: {
    // 重整 bid & ask
    info() {
      let timer1;
      this.detailsDataLoad(localStorage.getItem("detailsRic"));
      $("#info").attr("disabled", true);
      clearTimeout(timer1);
      //设置一次性定时器
      timer1 = setTimeout(function () {
        $("#info").attr("disabled", false);
      }, 5000);
    },
    //  Refresh 按钮提示
    handleMouse: function (flag) {
      this.mouse = flag;
    },
    //获取ric列表
    getRicList() {
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetRICList",
        data: { token: "webkey" },
        dataType: "json",
        success: data => {
          //fix start
          let parmUrl = location.search.substr(1).split("dsplay_nmll=")[1];
          $.each(data.ric, (index, comment) => {
            if (parmUrl != undefined && comment["dsply_nmll"] == parmUrl)
              localStorage.setItem("detailsRic", comment["ric"]);
          });
          //fix end
          $.each(data.ric, (index, comment) => {
            $("#details-combobox").append(
              "<option value=" +
              comment["ric"] +
              "," +
              comment["dsply_nmll"] +
              ">" +
              comment["dsply_nmll"] +
              " (" +
              comment["ticker"] +
              ")" +
              "</option>"
            );
          });
          this.ricChange();
          //判断是否是缓存ric
          if (localStorage.getItem("detailsRic") !== null) {
            this.detailsDataLoad(localStorage.getItem("detailsRic"));
          } else {
            this.dayWarrantsLoad();
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    ricChange() {
      var then = this;
      $("#details-combobox").combobox({
        select: function (event, ui) {
          localStorage.setItem("detailsRic", this.value.split(",")[0]);
          var routes = then.$router.resolve({
            path: "/id/WarrantDetails",
            query: { dsplay_nmll: this.value.split(",")[1] }
          });
          location.href = routes.href;
        }
      });
    },
    //获取details数据
    detailsDataLoad(ric) {
      $.ajax({
        type: "GET",
        url: this.$js.webservice + "GetPriceMatrixData",
        data: { token: "webkey", ric: ric },
        dataType: "json",
        success: data => {
          if (data.status === "SUCCESS") {
            this.detailsAppend(data.ric_data, data.soldout);
            this.detailsTableAppend(data.livematrix, data.ric_data);
            this.imageModalData(data.ric_data);
            this.dollarData(data.ric_data, data.exchangeratio);
            //给chart的ric赋值
            this.chartRic =
              data.ric_data.underlying_ric + "," + data.ric_data.ric;
            //
            this.underlyingRic = data.ric_data.underlying_ric;
            this.dsply_nmll = data.ric_data["dsply_nmll"];
            this.getChartData();
            this.underlying_curr = this.$js.curreryRetrun(
              data.ric_data.underlying_curr
            );
            this.currency = this.$js.curreryRetrun(data.ric_data.currency);
          }
        },
        error: function (error) {
          console.log(error);
        }
      });
    },
    //左边详情
    detailsAppend(ric_data, soldout) {
      //part1
      //改变meta的keywords
      $('meta[name="keywords"]').attr(
        "content",
        "warrant details, warrant terms, RHB, malaysia warrants," +
        ric_data["dsply_nmll"] +
        "," +
        ric_data["ticker"]
      );
      //combobox赋值
      $(".ui-widget")
        .find("input")
        .val(ric_data["dsply_nmll"] + " (" + ric_data["ticker"] + ")");
      if (Number(soldout) !== 0) {
        $(".soldout").empty();
        $(".soldout").append(
          "<span style='display: inline-block; color: red; font-size: 10px; border: 1px solid; padding: 2px 5px; margin: 8px 20px;'>Low On Inventory</span>"
        );
      }
      this.$js.clickEliminate(".ui-widget", true);
      $(".de-ucurrery").html(this.$js.curreryRetrun(ric_data.underlying_curr));
      $(".de-currery").html(this.$js.curreryRetrun(ric_data.currency));
      $(".underlying_bid").html(
        ric_data.underlying_bid !== ""
          ? this.$js.formatNumber(ric_data.underlying_bid, 0, 1)
          : "N/A"
      );
      $(".underlyingbid_netchng").html(
        ric_data.underlyingbid_netchng !== ""
          ? this.$js.formatNumber(ric_data.underlyingbid_netchng, 0, 0)
          : "N/A"
      );
      $(".underlyingbid_pctchng").html(
        ric_data.underlyingbid_pctchng !== ""
          ? this.$js.formatNumber(ric_data.underlyingbid_pctchng, 1, 0)
          : "N/A"
      );
      $(".BID").html(
        ric_data.BID !== "" ? this.$js.formatNumber(ric_data.BID, 0, 1) : "N/A"
      );
      $(".BID_NETCHNG").html(
        ric_data.BID_NETCHNG !== ""
          ? this.$js.formatNumber(ric_data.BID_NETCHNG, 0, 0)
          : "N/A"
      );
      $(".BID_PCTCHNG").html(
        ric_data.BID_PCTCHNG !== ""
          ? this.$js.changeRate(
            this.$js.formatNumber(ric_data.BID_PCTCHNG, 1, 0)
          )
          : "N/A"
      );
      //color
      $(".details-num div>span").each(function (index) {
        if (index !== 0 && index !== 1) {
          if ($(this).html() !== "N/A") {
            if ($(this).html() > 0) {
              $(this).css("color", "#009900");
              if (index !== 5 && index !== 6) {
                $(this).html("+" + $(this).html());
              }
            } else if ($(this).html() < 0) {
              $(this).css("color", "#FF0000");
            }
            if (index == 3 || index == 5) {
              $(this).html("&nbsp;(" + $(this).html() + "%)");
            }
          }
        }
      });

      //part2
      $(".img-issuer").html(
        this.$js.rewriteIssueName(ric_data.issuer_name) == "MSI"
          ? "Maybank"
          : this.$js.rewriteIssueName(ric_data.issuer_name)
      );
      $(".img-eff").html(
        ric_data.effective_gearing !== "N/A"
          ? this.$js.formatNumber(ric_data.effective_gearing, 1, 0) + " x"
          : "N/A"
      );
      $(".img-sen").html(
        ric_data.sensitivity !== "N/A"
          ? this.$js.formatNumber(ric_data.sensitivity, 1, 0) + " ticks"
          : "N/A"
      );
      $(".img-imv").html(
        ric_data.implied_volatility !== "N/A"
          ? this.$js.formatNumber(ric_data.implied_volatility, 1, 0) + " %"
          : "N/A"
      );
      $(".img-pre").html(
        ric_data.premium !== "N/A"
          ? this.$js.formatNumber(ric_data.premium, 1, 0) + " %"
          : "N/A"
      );
      $(".img-time").html(
        ric_data.TimeToMaturity !== "-"
          ? this.$js.formatdays(ric_data.TimeToMaturity)
          : "N/A"
      );
      $(".details-datenum span:first-child").html(ric_data.listingdate);
      $(".details-datenum span:last-child").html(ric_data.maturity);

      //$图标定位 start
      if (ric_data.listingdate === "-" || ric_data.maturity === "-") {
        $("span[data-target='#spanModal']").attr("style", "display:none");
        $(".left-money-bar").attr("style", "display:none");
        $(".right-money-bar").attr("style", "display:none");
      } else {
        var jsonlistingdate = new Date(
          this.$js.formatDetailDate(ric_data.listingdate)
        );
        var jsonmaturity = new Date(
          this.$js.formatDetailDate(ric_data.maturity)
        );
        var diffTime = Math.abs(jsonmaturity - jsonlistingdate);
        var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        var usindex =
          98 - ((diffDays - ric_data.TimeToMaturity) / diffDays) * 100;
        var leftmoneybar = 100 - usindex - 2;
        $("span[data-target='#spanModal']").attr(
          "style",
          "position: absolute; top: 0px; right: " + usindex + "%;"
        );
        $(".left-money-bar").attr("style", "width: " + leftmoneybar + "%");
        $(".right-money-bar").attr("style", "width: " + usindex + "%");
      }

      //$ end

      $(".update-time").html(this.$js.timeToAPM(ric_data.update_time));
      $(".type").html(ric_data.type);
    },
    //右边表格
    detailsTableAppend(live_matrix, ric_data) {
      var html = "";
      if (this.$js.isRHB(ric_data.issuer_name)) {
        let annotation = "annotation";
        var colorclass = "cc-wh";
        var pastvalue = 0;
        $.each(live_matrix, (index, datas) => {
          // if (index === 0) {
          //   thHtml += '<th scope="col" class="standard-bgcolor text-white border-right-0">' + _this.$t("m.bid") + '</th>'
          //   for (let i = 1; i <= 5; i++) {
          //     thHtml +=
          //       '<th scope="col" class="standard-bgcolor text-white border-right-0 dataTxt" >' +
          //       new Date(datas["date" + i]).toUTCString().split(" ")[1] +
          //       "-" +
          //       new Date(datas["date" + i]).toUTCString().split(" ")[2] +
          //       "</th>";
          //   }
          // }

          annotation =
            ric_data.underlying_bid === Number(datas.underlying_bid)
              ? "annotation"
              : " ";
          if (index == 0) {
            // html +=
            //   '<tr class="' +
            //   colorclass +
            //   '"><td class="' +
            //   annotation +
            //   '">' +
            //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid1, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid2, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid3, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid4, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid5, 0, 1) +
            //   "</td></tr>";
            html +=
              '<tr class="' +
              colorclass +
              " " +
              annotation +
              '"><td>' +
              this.$js.formatNumber(datas.underlying_bid, 0, 1) +
              "</td><td>" +
              datas.bid1 +
              "</td><td>" +
              datas.ask1 +
              "</td><td>" +
              this.$js.formatNumber(datas.underlying_ask, 0, 1) +
              "</td></tr>";
            pastvalue = datas.underlying_bid;
            colorclass = "cc-wh";
          } else if (datas.bid == pastvalue) {
            // html +=
            //   '<tr class="' +
            //   colorclass +
            //   '"><td class="' +
            //   annotation +
            //   '">' +
            //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid1, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid2, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid3, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid4, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid5, 0, 1) +
            //   "</td></tr>";
            html +=
              '<tr class="' +
              colorclass +
              " " +
              annotation +
              '"><td>' +
              this.$js.formatNumber(datas.underlying_bid, 0, 1) +
              "</td><td>" +
              datas.bid1 +
              "</td><td>" +
              datas.ask1 +
              "</td><td>" +
              this.$js.formatNumber(datas.underlying_ask, 0, 1) +
              "</td></tr>";
          } else if (datas.bid != pastvalue) {
            if (colorclass == "cc-wh") {
              colorclass = "cc-bg";
            } else {
              colorclass = "cc-wh";
            }
            // html +=
            //   '<tr class="' +
            //   colorclass +
            //   '"><td class="' +
            //   annotation +
            //   '">' +
            //   _this.$js.formatNumber(datas.underlying_bid, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid1, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid2, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid3, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid4, 0, 1) +
            //   "</td><td>" +
            //   _this.$js.formatNumber(datas.bid5, 0, 1) +
            //   "</td></tr>";
            html +=
              '<tr class="' +
              colorclass +
              " " +
              annotation +
              '"><td>' +
              this.$js.formatNumber(datas.underlying_bid, 0, 1) +
              "</td><td>" +
              datas.bid1 +
              "</td><td>" +
              datas.ask1 +
              "</td><td>" +
              this.$js.formatNumber(datas.underlying_ask, 0, 1) +
              "</td></tr>";
            pastvalue = datas.underlying_bid;
          }
        });
        if (live_matrix.length > 0) {
          html +=
            "<tr><td colspan='6'>Publish Time: " +
            this.$js.formatDate15(live_matrix[0].publish_time) +
            " GMT +7" +
            " </td></tr>";
        }
      } else {
        html =
          "<tr><td colspan='4'>Warrant matrix is only available for warrants issued by RHB</td></tr>";
      }
      $(".details-table tbody").empty();
      $(".details-table tbody").html(html);
      $(".underlying_ticker").text(ric_data.underlying_ticker);
      $(".dsply_nmll").text(ric_data["dsply_nmll"]);
    },
    //图标弹出框
    imageModalData(ric_data) {
      $(".dsply_nmll").html(ric_data.dsply_nmll);
      $(".Exercise_Price").html(
        ric_data.exercise_price !== "N/A"
          ? this.$js.curreryRetrun(ric_data.underlying_curr) +
          " " +
          this.$js.formatNumber(ric_data.exercise_price, 0, 1)
          : "N/A"
      );
      $(".Effective_Gearning").html(
        ric_data.effective_gearing !== "N/A"
          ? this.$js.formatNumber(ric_data.effective_gearing, 1, 0) + " x"
          : "N/A"
      );
      $(".Premium").html(
        ric_data.premium !== "N/A"
          ? this.$js.formatNumber(ric_data.premium, 1, 0) + " %"
          : "N/A"
      );
      $(".Exercise_Ratio").html(
        ric_data.conv_ratio !== "N/A"
          ? this.$js.formatNumber(ric_data.conv_ratio, 4, 0)
          : "N/A"
      );
      $(".Delta").html(
        ric_data.delta !== "N/A"
          ? this.$js.formatNumber(ric_data.delta, 1, 0) + " %"
          : "N/A"
      );
      $(".Implied_Volatility").html(
        ric_data.implied_volatility !== "N/A"
          ? this.$js.formatNumber(ric_data.implied_volatility, 1, 0) + " %"
          : "N/A"
      );
      $(".Expiry").html(ric_data.maturity);
      $(".Sensitivity").html(
        ric_data.sensitivity !== "N/A"
          ? this.$js.formatNumber(ric_data.sensitivity, 1, 0) + " ticks"
          : "N/A"
      );
      $(".Theta").html(
        ric_data.theta == "-"
          ? "N/A"
          : Math.abs(parseFloat(ric_data.theta)) < 0.0001
            ? "-0.0000"
            : this.$js.curreryRetrun(ric_data.underlying_curr) +
            " " +
            this.$js.formatNumber(ric_data.theta, 4, 0)
      );
      $(".Time_to_Maturity").html(
        ric_data.TimeToMaturity !== "-"
          ? this.$js.formatdays(ric_data.TimeToMaturity)
          : "N/A"
      );
      $(".price_at_expiry").html(
        ric_data.breakeven_price !== "N/A"
          ? this.$js.curreryRetrun(ric_data.underlying_curr) +
          " " +
          this.$js.formatNumber(ric_data.breakeven_price, 0, 1)
          : "N/A"
      );
      $(".Moneyness").html(
        ric_data.percent_moneyness !== "N/A"
          ? this.$js.formatNumber(ric_data.percent_moneyness, 1, 0) +
          " % " +
          this.getMoneyness(ric_data.moneyness)
          : "N/A"
      );
    },
    getMoneyness(value) {
      if (value == "0") {
        return "ATM";
      } else if (value == "1") {
        return "ITM";
      } else if (value == "2") {
        return "OTM";
      } else {
        return value;
      }
    },
    //美元弹出框
    dollarData(ric_data, rate_data) {
      //console.log(rate_data)
      //console.log(ric_data)
      var arr = [];
      for (var i = 1; i <= 5; i++) {
        if (
          ric_data["underlying_close" + i] !== "" ||
          ric_data["underlying_close" + i] !== undefined
        ) {
          arr.push(parseFloat(ric_data["underlying_close" + i]));
        }
      }
      var avg = this.sumAvg(arr);
      //计算公式 (5-Day Closing Average -  EXEXRISE PRICE) / Exercise Ratio * Quantity of Warrant Held
      var excPrice = this.$js.formatNumber(ric_data.exercise_price, 0, 0);
      var excRatio = this.$js.formatNumber(ric_data.conv_ratio, 4, 0);
      var result = 0;
      $(".underlying_ticker").html(ric_data.underlying_ticker);
      $(".Expiry_Date").html(ric_data.maturity);
      if (ric_data.type == "PUT") {
        $(".avgResult").html(excPrice);
        $(".excPrice").html(this.$js.formatNumber(avg, 0, 0));
      } else {
        $(".excPrice").html(excPrice);
        $(".avgResult").html(this.$js.formatNumber(avg, 0, 0));
      }
      $(".excPrice").html(excPrice);
      $(".excRatio").html(excRatio);
      $(".quantity-held").val(0);
      $(".quantityRut").html(0);
      $(".result").html(result);
      $(".underlying-curr").html(
        this.$js.curreryRetrun(ric_data.underlying_curr) + "&nbsp;"
      );
      $(".daysAvg").val(this.$js.formatNumber(avg, 0, 0));
      $(".Exercise_Prices").html(
        this.$js.curreryRetrun(ric_data.underlying_curr) +
        "&nbsp;" +
        this.$js.formatNumber(ric_data.exercise_price, 0, 1)
      );
      //货币转换判断 avgResult
      $(".avgResult").html(this.$js.formatNumber(avg, 0, 0));
      //     var currFlag = false;
      //     for (var e = 0;  e <rate_data.length; e++) {
      //      if (ric_data.underlying_curr ===rate_data[e].currency) {
      //         currFlag = true;
      //      }
      //     }
      //   if(currFlag){
      //     $(".avgResult").html(this.$js.formatNumber(avg/rate_data[0].rate, 3, 0));
      //   }else{
      //     $(".avgResult").html(this.$js.formatNumber(avg, 3, 0));
      //   }
      //输入框实时计算
      var then = this;
      $(".daysAvg").bind("input propertychange", function () {
        //货币转换判断计算
        //   var val = 0;
        //   if(currFlag){
        //     val = then.$js.formatNumber(parseFloat($(this).val()/rate_data[0].rate), 3, 0);
        //   }else{
        //     val = parseFloat($(this).val());
        //   }
        var val = parseFloat($(this).val());
        //
        var qua = $(".quantity-held").val() * 1;
        $(".avgResult").html(val);
        if ($(".type").html() == "PUT")
          result = ((excPrice - val) / excRatio) * qua * 1;
        else result = ((val - excPrice) / excRatio) * qua * 1;
        $(".result").html(
          then.$js.formatNumber(result, 0, 0) < 0
            ? 0
            : then.$js.formatNumber(result, 0, 0)
        );
      });
      $(".quantity-held").bind("input propertychange", function () {
        var val = $(this).val();
        $(".quantityRut").html(val);
        //货币转换判断计算
        // var avg = 0;
        // if(currFlag){
        // avg = then.$js.formatNumber(parseFloat($(".daysAvg").val()/rate_data[0].rate), 3, 0);;
        // }else{
        // avg = parseFloat($(".daysAvg").val());
        // }
        var avg = parseFloat($(".daysAvg").val());
        //
        if ($(".type").html() == "PUT")
          result = ((excPrice - avg) / excRatio) * val * 1;
        else result = ((avg - excPrice) / excRatio) * val * 1;
        $(".result").html(
          then.$js.formatNumber(result, 0, 0) < 0
            ? 0
            : then.$js.formatNumber(result, 0, 0)
        );
      });
    },
    //求平均数
    sumAvg(arr) {
      var len = arr.length;
      if (len == 0) {
        return 0;
      } else if (len == 1) {
        return arr[0];
      } else {
        return eval(arr.join("+")) / arr.length;
      }
    },
    //$动画
    spanAnimate() {
      $(".details-range .Rp").animate({ "background-color": "#fff" });
      setInterval(function () {
        $(".details-range .Rp").animate({ "background-color": "#009900" }, 10);
        $(".details-range .Rp").animate({ "background-color": "#fff" });
      }, 2000);
    },
    //时间点击
    dateClick() {
      var then = this;
      $(".details-btn div:nth-child(3)").addClass("details-btn-bg");
      $(".details-btn div").click(function () {
        $(this)
          .addClass("details-btn-bg")
          .siblings()
          .removeClass("details-btn-bg");
        console.log($(this).index());
        if ($(this).index() == 0) {
          then.chartDate = "d";
        } else if ($(this).index() == 1) {
          then.chartDate = "w";
        } else if ($(this).index() == 2) {
          then.chartDate = "m";
        } else if ($(this).index() == 3) {
          then.chartDate = "3m";
        } else if ($(this).index() == 4) {
          then.chartDate = "6m";
        } else if ($(this).index() == 5) {
          then.chartDate = "y";
        }
        then.getChartData();
      });
    },
    //下载table
    printTable() {
      this.$js.export_table(".details-table", "detailsTable");
    },
    //获取第一个ric
    dayWarrantsLoad() {
      $.ajax({
        type: "POST",
        dataType: "json",
        url: this.$js.webservice + "GetDeployedWarrantOfTheDay",
        data: { token: "webkey" },
        success: response => {
          //console.log(response);
          if (response.status == "SUCCESS") {
            this.detailsDataLoad(response.text[0].ric1);
          }
        },
        error: function (XMLHttpRequest) { }
      });
    },
    // 获取chart data
    getChartData() {
      $.ajax({
        type: "POST",
        dataType: "json",
        url:
          this.$js.webservice +
          "GetChartData?token=webkey&type=0&period=" +
          this.chartDate +
          "&ric=" +
          this.chartRic,
        success: res => {
          //res = {"underlyingDataList":[{"time":"04 Dec 2020 09:45","bid":8.280}],"replymsg":"","warrantDataList":[{"time":"04 Dec 2020 09:45","bid":0.21}],"status":"SUCCESS"};
          if (res.status == "SUCCESS") {
            var ulenth = res.underlyingDataList.length;
            var wlenth = res.warrantDataList.length;
            var arr = [];
            if (ulenth == wlenth) {
              //长度一样
              for (var i = 0; i < res.warrantDataList.length; i++) {
                var obj = {};
                obj["time"] =
                  this.chartDate == "d"
                    ? res.warrantDataList[i].time
                    : res.warrantDataList[i].date;
                obj["ubid"] =
                  this.chartDate == "d"
                    ? res.underlyingDataList[i].bid
                    : res.underlyingDataList[i].close;
                obj["wbid"] = res.warrantDataList[i].bid;
                arr.push(obj);
              }
            } else if (ulenth > wlenth) {
              //underlyingdatalist
              for (var i = 0; i < res.underlyingDataList.length; i++) {
                var obj = {};
                obj["time"] =
                  this.chartDate == "d"
                    ? res.underlyingDataList[i].time
                    : res.underlyingDataList[i].date;
                obj["ubid"] =
                  this.chartDate == "d"
                    ? res.underlyingDataList[i].bid
                    : res.underlyingDataList[i].close;
                $.each(res.warrantDataList, function (index, data) {
                  if (this.chartDate == "d") {
                    if (res.underlyingDataList[i].time == data.time) {
                      obj["wbid"] = data.bid;
                    }
                  } else {
                    if (res.underlyingDataList[i].date == data.date) {
                      obj["wbid"] = data.bid;
                    }
                  }
                });
                arr.push(obj);
              }
            } else if (wlenth > ulenth) {
              //warrantdatalist
              for (var i = 0; i < res.warrantDataList.length; i++) {
                var obj = {};
                obj["time"] =
                  this.chartDate == "d"
                    ? res.warrantDataList[i].time
                    : res.warrantDataList[i].date;
                $.each(res.underlyingDataList, function (index, data) {
                  if (this.chartDate == "d") {
                    if (data.time == res.warrantDataList[i].time) {
                      obj["ubid"] = data.bid;
                    }
                  } else {
                    if (data.date == res.warrantDataList[i].date) {
                      obj["ubid"] = data.close;
                    }
                  }
                });
                obj["wbid"] = res.warrantDataList[i].bid;
                arr.push(obj);
              }
            }
            this.detailsChartLoad(arr);
          }
        },
        error: function (XMLHttpRequest) { }
      });
    },

    detailsChartLoad(chartData) {
      $("#chart-container").empty(); //先清空
      var wbidArr = [];
      var ubidArr = [];
      $.each(chartData, function (index, data) {
        wbidArr.push(data.wbid);
        ubidArr.push(data.ubid);
      });
      var wbidy = this.getMaxMinNum(wbidArr);
      var ubidy = this.getMaxMinNum(ubidArr);
      var then = this;
      anychart.onDocumentReady(function () {
        //assigndata
        var arr = [];
        $.each(chartData, function (index, datas) {
          var obj = {};
          obj["date"] = datas.time;
          obj["bid"] = then.$js.formatNumber(datas.wbid, 0, 0);
          obj["price"] = then.$js.formatNumber(datas.ubid, 0, 0);
          arr.push(obj);
        });
        //赋值
        var dataSet = anychart.data.set(arr);
        var seriesData_1 = dataSet.mapAs({ x: "date", value: "bid" });
        var seriesData_2 = dataSet.mapAs({ x: "date", value: "price" });

        //生成chart
        var chart = anychart.area();
        chart.animation(true);

        // //十字线
        var crosshair = chart.crosshair();
        crosshair
          .enabled(true)
          .yStroke("#fff")
          .xStroke("#fff")
          .zIndex(39);
        crosshair.xStroke("#000");
        crosshair.yStroke("#000");

        // //设置x轴
        var axis = chart.xAxis();
        var labels = axis.labels();
        if (then.chartDate == "d") {
          labels.format(function () {
            // Set format for date time
            return anychart.format.dateTime(
              then.$js.changeToLocalTime(this.value),
              "HH:mm"
            );
          });
        } else {
          labels.format(function () {
            // Set format for date time
            return anychart.format.dateTime(
              then.$js.changeToLocalTime(this.value),
              "dd/MM"
            );
          });
        }

        // //悬浮
        var setupSeriesLabels = function (series, name) {
          series.name(name).fill(function () {
            return this.sourceColor + " 0.8";
          });
          series.hovered().stroke("3 #fff 1");
          series
            .hovered()
            .markers()
            .enabled(true)
            // .type('circle')
            .size(4)
            .stroke("1.5 #fff");
          series.markers().zIndex(100);
        };

        // // set titles for axises
        chart.xAxis().title(false);
        chart.yAxis().title(false);
        chart.interactivity().hoverMode("by-x");
        chart.tooltip().displayMode("union");
        chart
          .legend()
          .enabled(true)
          .fontSize(13)
          .padding([0, 0, 20, 0]);

        // // force chart to stack values by Y scale.
        chart.yScale().stackMode("value");

        // // set grid settings
        chart.xGrid().enabled(true);

        // //set font size / font-color
        chart
          .yAxis()
          .labels()
          .enabled(true)
          .fontColor("#181515")
          .fontSize(11);
        chart
          .yAxis(1)
          .labels()
          .enabled(true)
          .fontColor("#181515")
          .fontSize(11);
        chart
          .xAxis()
          .labels()
          .enabled(true)
          .fontColor("#181515")
          .fontSize(11);

        // //y -价钱3位小数
        chart
          .yAxis()
          .labels()
          .format(function () {
            return then.$js.formatNumber(this.value, 0, 0);
          });
        chart
          .yAxis(1)
          .labels()
          .format(function () {
            return then.$js.formatNumber(this.value, 0, 0);
          });

        // // Add area chart1
        var lineSeries = chart.area(seriesData_2);
        if (chartData == "d") {
          lineSeries
            .name("Underlying bid (" + then.underlying_curr + ")")
            .yScale();
        } else {
          lineSeries
            .name("Underlying price (" + then.underlying_curr + ")")
            .yScale();
        }
        lineSeries.yScale().maximum(ubidy[0] * 1.005);
        lineSeries.yScale().minimum(ubidy[1] * 0.995);
        lineSeries.tooltip().enabled(true);
        lineSeries.fill("rgba(91,194,231, 1)");
        lineSeries.tooltip().format(function (e) {
          var seriesName = this.seriesName;
          var value = this.value;
          return seriesName + ": " + then.$js.formatNumber(this.value, 0, 0);
        });

        // //add area chart2
        var scale = anychart.scales.linear();
        chart
          .yAxis(1)
          .orientation("right")
          .scale(scale);
        var series = chart.area(seriesData_1);
        setupSeriesLabels(series, "Warrant bid (" + then.currency + ")");
        series.yScale(scale);
        series.fill("rgba(0,103,177, 0.5)");
        series.yScale().maximum(wbidy[0] * 1.005);
        series.yScale().minimum(wbidy[1] * 0.995);
        series.tooltip().enabled(true);
        series.tooltip().format(function (e) {
          var seriesName = this.seriesName;
          var value = this.value;
          return seriesName + ": " + then.$js.formatNumber(this.value, 0, 0);
        });

        //set chart padding
        // series.xScale().mode('continuous');
        // lineSeries.xScale().mode('continuous');

        // // Get xGrid.
        var xGrid = chart.xGrid();
        xGrid.stroke("1 #f2f2f2");

        // set container id for the chart
        chart.container("chart-container");

        chart.draw();
      });
    },

    getMaxMinNum(arr) {
      var isEqeal = this.isAllEqual(arr);
      var mmArr = [];
      if (isEqeal) {
        //如果都相同
        mmArr.push(arr[0] * 2);
        mmArr.push(arr[0] % 2);
        return mmArr;
      } else {
        var max = Math.max.apply(Math, arr);
        mmArr.push(max);
        mmArr.push(Math.min.apply(Math, arr));
        return mmArr;
      }
    },

    isAllEqual(array) {
      if (array.length > 0) {
        return !array.some(function (value, index) {
          return value !== array[0];
        });
      } else {
        return true;
      }
    },

    toUnderlyingChart() {
      if (this.underlyingRic !== "") {
        localStorage.setItem("underlyingRic", this.underlyingRic);
        //location.href = "underlyingchart?dsplay_nmll=" + this.dsply_nmll;
        var routes = this.$router.resolve({
          path: "/id/UnderlyingChart",
          query: { dsplay_nmll: this.dsply_nmll }
        });
        location.href = routes.href;
      }
    }
  }
};
